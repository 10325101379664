var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stockManage" },
    [
      _c("van-nav-bar", {
        attrs: {
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: "",
          title: "存料管理"
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c("div", { staticClass: "totalMoney" }, [
        _c("h1", [
          _vm._v(
            _vm._s(
              _vm.data.status == "0"
                ? (
                    +_vm.data.mortgageFreeWeight * _vm.pricehj +
                    _vm.completeMoney +
                    +_vm.data.makeUpMoney -
                    +_vm.data.moneyTiCun -
                    +_vm.data.moneyExpress -
                    +_vm.data.moneyInsurance -
                    +_vm.data.warehouseMoney -
                    +_vm.data.money
                  ).toFixed(2)
                : "--"
            )
          )
        ]),
        _c("div", [_vm._v("实时剩余应收")])
      ]),
      _c("div", { staticClass: "moneyDetails" }, [
        _c("div", { staticClass: "weight" }, [
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.data.weight))
          ]),
          _c("div", { staticClass: "k" }, [_vm._v("存料克重(克)")])
        ]),
        _c("div", { staticClass: "money" }, [
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.data.mortgageFreeWeight))
          ]),
          _c("div", { staticClass: "k" }, [_vm._v("剩余存料克重(克)")])
        ]),
        _c("div", { staticClass: "money" }, [
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.data.initPrice))
          ]),
          _c("div", { staticClass: "k" }, [_vm._v("初始金价(元/克)")])
        ]),
        _c("div", { staticClass: "talMoney" }, [
          _c("div", { staticClass: "number" }, [
            _vm._v(
              _vm._s(_vm.data.status == "0" ? _vm.pricehj : _vm.data.nowPrice)
            )
          ]),
          _c("div", { staticClass: "k" }, [
            _vm._v(
              _vm._s(_vm.data.status == "0" ? "实时金价(/元克)" : "结算金价")
            )
          ])
        ])
      ]),
      _vm._m(0),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftText" }, [
          _vm._v(
            "累计仓租 (自" +
              _vm._s(
                _vm._f("formatTimeYear")(_vm._f("formatDate_")(_vm.data.time))
              ) +
              "起)"
          )
        ]),
        _c("div", { staticClass: "rightNumber" }, [
          _vm._v(_vm._s(_vm.data.warehouseMoney))
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftText" }, [_vm._v("首饰折价")]),
        _c("div", { staticClass: "rightNumber" }, [
          _vm._v(_vm._s(_vm.data.moneyTiCun))
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftText" }, [_vm._v("快递费")]),
        _c("div", { staticClass: "rightNumber" }, [
          _vm._v(_vm._s(_vm.data.moneyExpress))
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftText" }, [_vm._v("保险费")]),
        _c("div", { staticClass: "rightNumber" }, [
          _vm._v(_vm._s(_vm.data.moneyInsurance))
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftText" }, [_vm._v("已收金额")]),
        _c("div", { staticClass: "rightNumber" }, [
          _vm._v(_vm._s(_vm.data.money))
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftText" }, [_vm._v("补交押金")]),
        _c("div", { staticClass: "rightNumber" }, [
          _vm._v(_vm._s(_vm.data.makeUpMoney))
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "leftbtmText" }, [_vm._v("剩余比例")]),
        _c(
          "div",
          {
            staticClass: "rightbtmNumber",
            style: {
              color: _vm.data.isOverPercent == "true" ? "red" : "#60b672"
            }
          },
          [
            _vm._v(
              _vm._s(
                _vm.data.status == "0"
                  ? (
                      ((+_vm.data.mortgageFreeWeight * _vm.pricehj +
                        _vm.completeMoney +
                        +_vm.data.makeUpMoney -
                        _vm.data.money -
                        +_vm.data.moneyTiCun -
                        +_vm.data.moneyExpress -
                        +_vm.data.moneyInsurance -
                        +_vm.data.warehouseMoney) *
                        100) /
                      (+_vm.data.initPrice * +_vm.data.mortgageFreeWeight)
                    ).toFixed(2)
                  : _vm.data.currentDepositPercent
              )
            )
          ]
        )
      ]),
      _vm._m(1),
      _c("div", { staticClass: "jiesuan" }, [
        _c(
          "div",
          {
            staticClass: "jieSuan",
            style: {
              backgroundColor: _vm.data.status == "0" ? "#ff2d2e" : "#ccc"
            },
            on: { click: _vm.balance }
          },
          [
            _vm._v(
              _vm._s(
                _vm.data.isOver == "true"
                  ? "已结算"
                  : _vm.data.status == "0"
                  ? "结算"
                  : "结算申请已提交"
              )
            )
          ]
        )
      ]),
      _vm.data.referenceInfoList
        ? _c("div", [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "list" },
              [
                _vm._m(3),
                _vm._l(_vm.data.referenceInfoList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "listContent flex_between" },
                    [
                      _c("div", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatDate_")(item.time, "yyyy-MM-dd"))
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "price",
                          staticStyle: { width: "80px" }
                        },
                        [_vm._v(_vm._s(item.price))]
                      ),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.referenceWeight))
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.money))
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _c("statementAfter", {
        ref: "statementPop",
        attrs: {
          showStatementAfter: _vm.showStatementAfter,
          hjPrice: _vm.pricehj,
          freeWeight: _vm.data.mortgageFreeWeight,
          openId: _vm.openId,
          postTime: _vm.postTime,
          type: "mortgage"
        },
        on: {
          "update:showStatementAfter": function($event) {
            _vm.showStatementAfter = $event
          },
          "update:show-statement-after": function($event) {
            _vm.showStatementAfter = $event
          }
        }
      }),
      _c(
        "van-dialog",
        {
          attrs: { title: "提示", "show-cancel-button": "" },
          on: { confirm: _vm.confirm },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("p", [
            _vm._v(
              "您正在进行结算操作,提交结算申请后工作人员将尽快为您审核,是否确定结算?"
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "costCalculation" }, [
      _c("div", { staticClass: "icon" }),
      _c("h3", [_vm._v("费用计算")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bucang" }, [
      _c("span", { staticClass: "left" }, [_vm._v("如需补仓,请拔打电话")]),
      _c("span", { staticClass: "right" }, [_vm._v("4008-606-806")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "costCalculation" }, [
      _c("div", { staticClass: "icon" }),
      _c("h3", [_vm._v("平仓明细")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "listContent flex" }, [
      _c("div", { staticClass: "time" }, [_vm._v("时间")]),
      _c("div", { staticClass: "price", staticStyle: { width: "80px" } }, [
        _vm._v("平仓价格")
      ]),
      _c("div", { staticClass: "price" }, [_vm._v("平仓克重")]),
      _c("div", { staticClass: "price" }, [_vm._v("平仓金额")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }